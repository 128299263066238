import type { SiteFormatValues } from '@whoop/i18n/utils/priceHelpers';
import { formatPriceWithSiteValues } from '@whoop/i18n/utils/priceHelpers';
import type { CartItemType, PromoCodeState } from '../types';
import { getPromoPercentDiscount } from './promotionHelpers';

export const getDiscountValues = (
  product: CartItemType,
  siteValues: SiteFormatValues,
  promoCode?: PromoCodeState,
  round = true,
) => {
  let discountAmount = product.percent_discount ?? 0;

  if (promoCode && product.membership_type !== 'family') {
    discountAmount = getPromoPercentDiscount(
      product,
      siteValues.currency,
      product.display_price,
      promoCode,
    );
  }

  const unformattedDisplayPrice = product.display_price;
  const discountedPrice =
    unformattedDisplayPrice -
    unformattedDisplayPrice * (discountAmount / 100.0);

  const priceToUse = round
    ? Math.ceil(discountedPrice / 100) * 100
    : discountedPrice;

  const displayPrice = formatPriceWithSiteValues(priceToUse, siteValues, {
    showCents: false,
  });
  const originalDisplayPrice = formatPriceWithSiteValues(
    product.display_price,
    siteValues,
    { showCents: false },
  );
  return {
    displayPrice,
    originalDisplayPrice,
    isDiscounted: Boolean(discountAmount),
  };
};
