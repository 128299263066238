import React from 'react';
import { useTranslation } from '@whoop/i18n/lang/client';
import type {
  Currency,
  Language,
  Region,
} from '@whoop/i18n/types/internationalization';
import clsx from 'clsx';
import Image from 'next/image';
import type { CheckoutPromoCode } from 'services/generated/membership-service-v2/models/CheckoutPromoCode';
import { getDiscountValues } from '../../../utils';
import type {
  CartAlertContent,
  CartProduct,
  CartUpsellContent,
  PromoInfo,
} from '../../../types';
import { CartQuantitySelector } from '../CartQuantitySelector/CartQuantitySelector';
import { CartUpsell } from '../CartUpsell/CartUpsell';
import LayeredImage from '../../LayeredImage/LayeredImage';
import Alert from '../../Alert/Alert';

interface CartItemProps extends React.HTMLAttributes<HTMLDivElement> {
  currency: Currency;
  item: CartProduct;
  onQuantityChange: (newQuantity: number) => void;
  isLocked?: boolean;
  alert?: CartAlertContent;
  upsell?: CartUpsellContent;
  isLastItem?: boolean;
  language: Language;
  isV2OrderPage?: boolean;
  region: Region;
  promoInfo?: PromoInfo;
}

export function CartItem({
  className,
  currency,
  item,
  onQuantityChange,
  isLocked = true,
  alert,
  upsell,
  language,
  region,
  isV2OrderPage = false,
  promoInfo,
  ...props
}: CartItemProps): React.JSX.Element {
  const { t } = useTranslation('cart');

  const cartInfo = item.item.cart_information;
  const inventory = item.item.inventory_information;
  const membershipType = item.item.membership_type;
  const canChangeQuantity = !isLocked && Boolean(inventory);

  // Don't show the discount for the bundle promo code
  const applyPromoCode = (
    promoInfo?: PromoInfo,
  ): CheckoutPromoCode | undefined =>
    promoInfo?.promo_code.code !== 'PREPAIDBUNDLE' &&
    promoInfo?.promo_code.code !== 'PREPAIDBUNDLEAFFILIATE'
      ? promoInfo?.promo_code
      : undefined;

  const { displayPrice, originalDisplayPrice, isDiscounted } =
    getDiscountValues(
      item.item,
      { currency, language, region },
      applyPromoCode(promoInfo),
      false,
    );

  const displayPriceTouse =
    item.item.display_price === 0 ? t('free') : displayPrice;

  const displayCartQuantitySelector =
    !membershipType || membershipType != 'family';

  return (
    <div
      className={clsx(
        isV2OrderPage ? '' : 'gray-40 border-b',
        className,
        alert ? 'pb-6' : '',
      )}
      {...props}
    >
      <div
        className={clsx(
          'flex justify-between',
          alert ? 'pb-4 pt-6' : 'py-4 md:py-6',
        )}
      >
        <div className='mr-6 flex h-fit min-w-max items-center justify-center rounded-lg bg-white'>
          {cartInfo.image ? (
            <Image
              alt={cartInfo.name}
              className='inline-block h-10 w-10'
              height={12}
              src={cartInfo.image}
              width={12}
            />
          ) : null}
          {'images' in cartInfo ? (
            <LayeredImage
              className='inline-block h-12 w-12 max-w-[48px]'
              images={cartInfo.images}
            />
          ) : null}
        </div>
        <div className='flex w-full flex-col'>
          <div className='flex justify-between'>
            <div className='flex-1 pr-4 align-[1rem] text-base font-normal'>
              {cartInfo.name}
            </div>
            <div className='float-right text-right text-base font-normal tracking-normal'>
              {displayPriceTouse}
              <span
                className={clsx(
                  'font-regular text-gray-b50 line-through',
                  isDiscounted ? 'pl-2' : '',
                )}
              >
                {isDiscounted ? originalDisplayPrice : null}
              </span>
            </div>
          </div>
          <div className='flex justify-between'>
            {/* Description for products with a one-line description (typically the variant) or no description (product has no variants) */}
            {cartInfo.description || !('descriptions' in cartInfo) ? (
              <div className='text-inactive w-10/12 pr-2 text-sm'>
                {cartInfo.description}
              </div>
            ) : null}
            {/* Description for products with a multiple-lines-long description (WYW & Engraving) */}
            {'descriptions' in cartInfo ? (
              <div className='text-inactive pr-2 text-sm'>
                {cartInfo.descriptions.map((line) => (
                  <div key={line}>{line}</div>
                ))}
              </div>
            ) : null}
            {displayCartQuantitySelector ? (
              <div className='text-inactive flex text-sm md:p-0'>
                <CartQuantitySelector
                  isLocked={!canChangeQuantity || isV2OrderPage}
                  maxQuantity={inventory?.max_quantity}
                  minQuantity={inventory?.min_quantity}
                  onQuantityChange={onQuantityChange}
                  value={item.quantity}
                />
              </div>
            ) : null}
          </div>
          {upsell && !isV2OrderPage ? <CartUpsell upsell={upsell} /> : null}
        </div>
      </div>
      {alert ? (
        <Alert
          action={alert.action}
          actionLabel={alert.actionLabel}
          description={alert.message}
          showIcon={false}
          type='infoPlain'
        />
      ) : null}
    </div>
  );
}
