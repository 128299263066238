import type { SVGProps } from 'react';

function Attention({
  width = 22,
  height = 23,
  role = 'presentation',
  color,
  ...attributes
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      fill='none'
      height={height}
      role={role}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      {...attributes}
    >
      <rect fill={color ?? 'white'} height='22' rx='11' width='22' y='0.5' />
      <path
        d='M10.4395 13.5417H11.123C11.4982 13.5417 11.8021 13.22 11.8021 12.9595L12.3125 5.9572C12.3125 5.63551 12.0084 5.375 11.6335 5.375H9.92904C9.55384 5.375 9.25 5.69669 9.25 5.9572L9.76042 12.9595C9.76042 13.2812 10.0645 13.5417 10.4395 13.5417Z'
        fill='white'
      />
      <path
        d='M10.4395 17.625H11.123C11.4982 17.625 11.8021 17.3434 11.8021 17.1154V16.0929C11.8021 15.8113 11.498 15.5833 11.123 15.5833H10.4395C10.0643 15.5833 9.76042 15.8649 9.76042 16.0929V17.1154C9.76042 17.397 10.0645 17.625 10.4395 17.625Z'
        fill='white'
      />
    </svg>
  );
}

export default Attention;
