import * as React from 'react';
import * as DialogPrimitive from '@radix-ui/react-dialog';
import clsx from 'clsx';
import CloseIcon from '../../icons/close';

/**
 * @description Modal implementation from Shadcn
 * @documentation Shadcn wrapper around radix https://ui.shadcn.com/docs/components/dialog
 * @see https://www.radix-ui.com/primitives/docs/components/dialog#root
 */
const Modal = DialogPrimitive.Root;

/**
 * @see https://www.radix-ui.com/primitives/docs/components/dialog#trigger
 */
const ModalTrigger = DialogPrimitive.Trigger;

/**
 * @see https://www.radix-ui.com/primitives/docs/components/dialog#portal
 */
const ModalPortal = DialogPrimitive.Portal;

/**
 * @see https://www.radix-ui.com/primitives/docs/components/dialog#close
 */
const ModalClose = DialogPrimitive.Close;

/**
 * @see https://www.radix-ui.com/primitives/docs/components/dialog#overlay
 */
const ModalOverlay = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Overlay>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Overlay
    className={clsx(
      'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0  fixed inset-0 z-50 bg-black/50',
      className,
    )}
    ref={ref}
    {...props}
  />
));
ModalOverlay.displayName = DialogPrimitive.Overlay.displayName;

/**
 * @see https://www.radix-ui.com/primitives/docs/components/dialog#content
 */
const ModalContent = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Content> & {
    hideCloseButton?: boolean;
    datatestid?: string;
    headerContent?: React.ReactNode;
    stickyHeader?: boolean;
  }
>(
  (
    {
      className,
      children,
      hideCloseButton,
      headerContent,
      stickyHeader,
      ...props
    },
    ref,
  ) => (
    <ModalPortal>
      <ModalOverlay className='z-drawer' />
      <DialogPrimitive.Content
        className={clsx(
          'data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] z-modal fixed left-[50%] top-[50%] grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] border bg-white px-6 pb-6 shadow-lg duration-200 sm:rounded-lg',
          className,
        )}
        ref={ref}
        {...props}
      >
        <div
          className={clsx(
            'min-h-6 flex w-auto flex-row justify-between px-3',
            stickyHeader
              ? 'sticky left-0 right-0 top-0 z-20 bg-white py-3.5 md:px-0 md:py-6'
              : 'w-full pb-2 pt-6',
          )}
        >
          {headerContent}
          {!hideCloseButton && (
            <div className={clsx('ml-auto h-6')}>
              <DialogPrimitive.Close className='disabled:pointer-events-none'>
                <CloseIcon pathStroke='black' title='Close modal' />
                <span className='sr-only'>Close modal</span>
              </DialogPrimitive.Close>
            </div>
          )}
        </div>
        {children}
      </DialogPrimitive.Content>
    </ModalPortal>
  ),
);
ModalContent.displayName = DialogPrimitive.Content.displayName;

const ModalHeader = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>): JSX.Element => (
  <div
    className={clsx(
      'flex flex-col space-y-1.5 text-center sm:text-left',
      className,
    )}
    {...props}
  />
);
ModalHeader.displayName = 'ModalHeader';

const ModalFooter = ({
  className,
  ...props
}: React.HTMLAttributes<HTMLDivElement>): JSX.Element => (
  <div
    className={clsx(
      'flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2',
      className,
    )}
    {...props}
  />
);
ModalFooter.displayName = 'ModalFooter';

/**
 * @see https://www.radix-ui.com/primitives/docs/components/dialog#title
 */
const ModalTitle = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Title>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Title>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Title
    className={clsx(
      'text-lg font-semibold leading-none tracking-tight',
      className,
    )}
    ref={ref}
    {...props}
  />
));
ModalTitle.displayName = DialogPrimitive.Title.displayName;

/**
 * @see https://www.radix-ui.com/primitives/docs/components/dialog#description
 */
const ModalDescription = React.forwardRef<
  React.ElementRef<typeof DialogPrimitive.Description>,
  React.ComponentPropsWithoutRef<typeof DialogPrimitive.Description>
>(({ className, ...props }, ref) => (
  <DialogPrimitive.Description
    className={clsx('text-sm text-gray-600', className)}
    ref={ref}
    {...props}
  />
));
ModalDescription.displayName = DialogPrimitive.Description.displayName;

export {
  Modal,
  ModalPortal,
  ModalOverlay,
  ModalTrigger,
  ModalClose,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalTitle,
  ModalDescription,
};
