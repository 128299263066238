import type { SVGProps } from 'react';

type IconProps = SVGProps<SVGSVGElement> & {
  title?: string;
  pathFill?: SVGProps<SVGPathElement>['fill'];
  pathStroke?: SVGProps<SVGPathElement>['color'];
  maskFill?: SVGProps<SVGMaskElement>['fill'];
};

function CloseIcon({
  title,
  width = 24,
  height = 24,
  fill = 'none',
  role = 'presentation',
  pathStroke = 'white',
  ...attributes
}: IconProps) {
  const viewBoxSize = 24;

  return (
    <svg
      fill={fill}
      focusable='false'
      height={height}
      role={role}
      viewBox={`0 0 ${viewBoxSize} ${viewBoxSize}`}
      width={width}
      {...attributes}
      xmlns='http://www.w3.org/2000/svg'
    >
      {title ? <title>{title}</title> : null}

      <path
        d='M17.3145 17.3145L6.00074 6.00074'
        stroke={pathStroke}
        strokeWidth='1.5'
      />
      <path
        d='M17.3145 6L6.00074 17.3137'
        stroke={pathStroke}
        strokeWidth='1.5'
      />
    </svg>
  );
}

export default CloseIcon;
