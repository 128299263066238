'use client';

import React from 'react';
import { useTranslation } from '@whoop/i18n/lang/client';
import clsx from 'clsx';
import type { DropdownOption } from '../../../types';
import { DropdownSelect } from '../../DropdownSelect/DropdownSelect';
import styles from './cartQuantitySelector.module.scss';

interface QuantitySelectorProps extends React.HTMLAttributes<HTMLDivElement> {
  isLocked: boolean;
  maxQuantity?: number;
  minQuantity?: number;
  onQuantityChange?: (newQuantity: number) => void;
  // if the first option is remove and thats not what we want to show, then we can pass in the selected option
  value?: number;
}
export function CartQuantitySelector({
  className,
  isLocked,
  maxQuantity,
  minQuantity,
  onQuantityChange,
  value,
  ...props
}: QuantitySelectorProps): React.JSX.Element {
  const { t } = useTranslation('cart');
  const defaultQuantity = 1;

  const handleOptionSelect = (option: string): void => {
    const newQuantity = parseInt(option);
    onQuantityChange && onQuantityChange(newQuantity);
  };

  const getDisplayOptions = (): DropdownOption[] => {
    const options: DropdownOption[] = [];
    for (
      let quantity = minQuantity ?? defaultQuantity;
      quantity <= (maxQuantity ?? defaultQuantity);
      quantity++
    ) {
      const label = quantity.toString();
      options.push({
        label: quantity === 0 ? t('remove') : label,
        value: label,
      });
    }
    return options;
  };

  return (
    <div className={clsx(className, styles.cartQuantitySelector)} {...props}>
      <span className={clsx(styles.text, 'p5')}>{t('quantity')} </span>
      {isLocked || getDisplayOptions().length === 1 ? (
        <span className={styles.lockedQuantity}>{value ?? 1}</span>
      ) : (
        <DropdownSelect
          className={styles.dropdown}
          onOptionSelected={handleOptionSelect}
          options={getDisplayOptions()}
          value={value?.toString() ?? defaultQuantity.toString()}
        />
      )}
    </div>
  );
}
