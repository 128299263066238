import type { SVGProps } from 'react';

function CrossIcon({
  width = 32,
  height = 32,
  role = 'presentation',
  color,
  ...attributes
}: SVGProps<SVGSVGElement>): JSX.Element {
  return (
    <svg
      fill='none'
      height={height}
      role={role}
      viewBox={`0 0 ${width} ${height}`}
      width={width}
      xmlns='http://www.w3.org/2000/svg'
      {...attributes}
    >
      <path
        d='M6.32074 23.3562C5.89309 23.7839 5.89309 24.4773 6.32074 24.9049L7.09508 25.6793C7.52274 26.1069 8.21611 26.1069 8.64377 25.6793L16 18.323L23.3562 25.6793C23.7839 26.1069 24.4773 26.1069 24.9049 25.6793L25.6793 24.9049C26.1069 24.4773 26.1069 23.7839 25.6793 23.3562L18.323 16L25.6793 8.64376C26.1069 8.21611 26.1069 7.52274 25.6793 7.09508L24.9049 6.32074C24.4773 5.89309 23.7839 5.89309 23.3562 6.32074L16 13.677L8.64376 6.32074C8.21611 5.89309 7.52274 5.89309 7.09508 6.32074L6.32074 7.09508C5.89309 7.52274 5.89309 8.21611 6.32074 8.64377L13.677 16L6.32074 23.3562Z'
        fill={color ?? 'white'}
      />
    </svg>
  );
}

export default CrossIcon;
