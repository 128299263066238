'use client';
import { useState } from 'react';
import clsx from 'clsx';
import Success from '../../icons/icons/Toast/success';
import CrossIcon from '../../icons/crossIcon';
import Info from '../../icons/icons/Toast/info';
import Attention from '../../icons/icons/Toast/attention';

type AlertType = 'error' | 'warning' | 'info' | 'success' | 'infoPlain';

const textColor: Record<AlertType, string> = {
  error: 'text-red-a400',
  warning: 'text-yellow-400',
  info: 'text-blue-a800/80',
  success: 'text-green-500',
  infoPlain: 'text-gray-a300',
};

const backgroundColor: Record<AlertType, string> = {
  error: 'bg-red-100',
  warning: 'bg-yellow-50',
  info: 'bg-sky-100',
  success: 'bg-green-100',
  infoPlain: 'bg-none',
};

const icon: Record<AlertType, JSX.Element> = {
  error: <Attention color='#FF0026' />,
  warning: <Attention color='#FACC15' />,
  info: <Info className='scale-75' color='#3B82F6' />,
  success: <Success className='scale-75' color='#22C55E' />,
  infoPlain: <Info className='scale-75' />,
};

export interface AlertProps {
  title?: string;
  description: string;
  type?: AlertType;
  showCloseIcon?: boolean;
  onClose?: () => void;
  showIcon?: boolean;
  actionLabel?: string;
  action?: () => void;
  centerContent?: boolean;
}
export default function Alert({
  title,
  description,
  type = 'error',
  showCloseIcon = false,
  onClose,
  showIcon = true,
  actionLabel,
  action,
  centerContent = false,
}: AlertProps): JSX.Element {
  const [dismissed, setDismissed] = useState(false);

  const handleClose = (): void => {
    setDismissed(true);
    if (onClose) {
      onClose();
    }
  };

  return (
    <div
      className={clsx(
        `p-sm flex h-fit w-full flex-row items-center rounded-2xl ${
          backgroundColor[type]
        } ${centerContent ? 'justify-center' : ''}`,
        dismissed ? 'hidden' : '',
      )}
    >
      {showIcon ? <div className='mr-3 flex'>{icon[type]}</div> : null}
      <div
        className={`flex ${
          centerContent ? '' : 'flex-1'
        } flex-row items-center`}
      >
        <div className='flex flex-1 flex-col'>
          {title ? (
            <span
              aria-label={title}
              className={`text-base font-semibold ${textColor[type]}`}
            >
              {title}
            </span>
          ) : null}
          <span
            aria-label={description}
            className={`text-sm font-normal ${textColor[type]}`}
          >
            {description}
            {action && actionLabel ? (
              <button
                className='text-blue-strain pl-1 underline'
                onClick={action}
                type='button'
              >
                {actionLabel}
              </button>
            ) : null}
          </span>
        </div>
        {showCloseIcon ? (
          <div className='ml-3 flex'>
            <button onClick={handleClose} type='button'>
              <CrossIcon className='scale-50' color='black' />
            </button>
          </div>
        ) : null}
      </div>
    </div>
  );
}
