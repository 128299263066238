import React from 'react';
import clsx from 'clsx';
import type { DropdownOption } from '../../types';
import { DropdownThemes } from '../../types';
import styles from './dropdownSelect.module.scss';

interface DropdownSelectProps extends React.HTMLAttributes<HTMLDivElement> {
  options: DropdownOption[];
  onOptionSelected: (option: string) => void;
  value?: string;
  label?: string;
  theme?: DropdownThemes;
  round?: boolean;
  autoComplete?: string;
  error?: boolean;
}

export function DropdownSelect({
  options,
  onOptionSelected,
  value,
  label,
  className,
  theme = DropdownThemes.DARK,
  round = true,
  autoComplete,
  error = false,
  ...props
}: DropdownSelectProps): React.JSX.Element {
  return (
    <div
      className={clsx(
        styles.dropdown,
        theme !== DropdownThemes.DARK ? `theme-${theme}` : '',
        className,
      )}
      {...props}
    >
      <select
        autoComplete={autoComplete}
        className={clsx(
          styles.dropdownButton,
          round ? styles.round : styles.squared,
          error ? 'border-red-form' : '',
        )}
        onChange={(event) => onOptionSelected(event.target.value)}
        value={value ?? ''}
      >
        {label ? (
          <option data-testid='label-option' disabled value=''>
            {label}
          </option>
        ) : null}
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
}
