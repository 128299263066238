import { i18n } from '@whoop/i18n/lang/client';
import {
  getCardAmounts,
  getPercentSavings,
  isAccessory,
  isBattery,
  isDevice,
  isEngraving,
  isMembership,
  isPack,
  buildWywProductFromSku,
  findAccessoryProductFromSku,
  getMembershipFromSku,
} from 'ui';
import { getDefaultDeviceProductFromSku } from 'ui/utils/deviceHelpers';
import type {
  AccessoryProduct,
  CartItemType,
  MembershipProduct,
  CartAlertContent,
  CartDisplayItem,
  CartProduct,
  CartUpsellContent,
  AnyProduct,
  JoinProducts,
  PromoCode,
  PackProduct,
  WhoopYourWayConfigurations,
} from 'ui';
import type {
  Currency,
  Language,
  Region,
} from '@whoop/i18n/types/internationalization';
import type { AccessoryGroup } from 'services/generated/growth-content-service';
import { countBatteriesInPack } from 'ui/utils/packHelpers';
import type { FinalCartItem } from 'services/generated/order-service';
import type { CartButtonVariants } from '@/components/CartCTAButtons/CartCTAButtons';
import {
  buildEngravingProductFromParams,
  canAddEngraving,
  getEngravingGroup,
  hasAddedEngraving,
} from '@/lib/utils/engravingHelper';
import {
  DIRECT_TO_CHECKOUT_PAID,
  DIRECT_TO_CHECKOUT_TRIALS,
} from '../constants/experiments';
import { formatDisplayPrice } from './formatHelpers';
import { findMembershipByTrialMonths } from './membershipHelper';
import { isQuantityAllowed, getCartMembershipType } from './cartManager';

interface StateProps {
  requiredProducts: JoinProducts;
  currency: Currency;
}

interface StateActions {
  addToCart: (
    product: AnyProduct,
    quantity?: number,
    updateQuantity?: boolean,
  ) => void;
  updateProductQuantity: (product: CartProduct, newQuantity: number) => void;
  changeMembershipAction?: () => void;
}

interface LearnMoreAction extends StateProps {
  learnMoreAction?: () => void;
}

interface CartDisplayItemsOptions extends LearnMoreAction, StateActions {
  cartProducts: CartProduct[];
  engravingUpsellAction?: () => void;
  changeMembershipAction?: (
    membershipType?: string,
    familySize?: string,
  ) => void;
  accessories: AccessoryGroup[];
  language: Language;
  promoCode?: PromoCode;
  region: Region;
  shouldSkipMemSelect?: boolean;
}

interface CartItemAlertOptions extends LearnMoreAction {
  product: CartProduct;
  language: Language;
  region: Region;
}

interface MembershipCartAlertOptions extends LearnMoreAction {
  membership: MembershipProduct;
  language: Language;
  region: Region;
}

interface MembershipCartUpsellOptions extends StateActions, StateProps {
  product: CartProduct;
  shouldSkipMemSelect?: boolean;
  changeMembershipAction?: (
    membershipType?: string,
    familySize?: string,
  ) => void;
}

interface DeviceCartUpsellOptions {
  allProducts: CartProduct[];
  engravingUpsellAction: () => void;
  accessories: AccessoryGroup[];
  currency: Currency;
  language: Language;
  region: Region;
}

interface CartItemUpsellOptions {
  product: CartProduct;
  allProducts: CartProduct[];
  currency: Currency;
  requiredProducts: JoinProducts;
  addToCart: (
    product: AnyProduct,
    quantity?: number,
    updateQuantity?: boolean,
  ) => void;
  updateProductQuantity: (product: CartProduct, newQuantity: number) => void;
  accessories: AccessoryGroup[];
  engravingUpsellAction?: () => void;
  changeMembershipAction?: (
    membershipType?: string,
    familySize?: string,
  ) => void;
  language: Language;
  region: Region;
  shouldSkipMemSelect?: boolean;
}

export type CTAButtonsExperimentName =
  | typeof DIRECT_TO_CHECKOUT_TRIALS
  | typeof DIRECT_TO_CHECKOUT_PAID;
interface CTAButtonsExperimentOptions {
  experimentName: CTAButtonsExperimentName;
  experimentVariant: string;
}

export function getMembershipCartAlert({
  membership,
  learnMoreAction,
  requiredProducts,
  currency,
  language,
  region,
}: MembershipCartAlertOptions): CartAlertContent | undefined {
  if (membership.membership_type !== 'trial') return;

  const annualMembership = findMembershipByTrialMonths(
    requiredProducts.join_memberships,
    12,
  );
  if (!annualMembership) return;
  const yearlyPrice = formatDisplayPrice(annualMembership.display_price, {
    currency,
    language,
    region,
  });

  return {
    message: i18n.t('orderPage:trialAlert', { yearlyPrice }),
    action: learnMoreAction,
    actionLabel: learnMoreAction ? i18n.t('cart:learnMore') : undefined,
  };
}

export function getCartItemAlert({
  product,
  learnMoreAction,
  requiredProducts,
  currency,
  language,
  region,
}: CartItemAlertOptions): CartAlertContent | undefined {
  const item = product.item;
  if (isMembership(item)) {
    return getMembershipCartAlert({
      membership: item as MembershipProduct,
      learnMoreAction,
      requiredProducts,
      currency,
      language,
      region,
    });
  }
}

export const getMembershipCartUpsell = ({
  product,
  currency,
  requiredProducts,
  addToCart,
  updateProductQuantity,
  changeMembershipAction,
  shouldSkipMemSelect,
}: MembershipCartUpsellOptions): CartUpsellContent | undefined => {
  const membership = product.item as MembershipProduct;

  if (
    shouldSkipMemSelect &&
    membership.membership_type !== 'family' &&
    changeMembershipAction
  ) {
    return {
      label: i18n.t('cart:changeMembership'),
      action: () => changeMembershipAction('prepaid'),
    };
  }

  if (membership.membership_type === 'monthly') {
    const allMemberships = requiredProducts.join_memberships;
    const annualMembership = findMembershipByTrialMonths(allMemberships, 12);
    if (!annualMembership) return;
    // to-do: update with promo code
    const { percentSavingsOverMonthly } = getCardAmounts(
      annualMembership,
      allMemberships,
      currency,
    );
    if (!percentSavingsOverMonthly) return;
    return {
      label: i18n.t('cart:switchToAnnualBilling', {
        percentSavings: percentSavingsOverMonthly,
      }),
      action: () => addToCart(annualMembership, 1),
    };
  }

  if (membership.membership_type === 'prepaid') {
    const newQuantity = product.quantity + 1;
    if (isQuantityAllowed(membership, newQuantity)) {
      return {
        label: i18n.t('cart:addAMembership'),
        action: () => updateProductQuantity(product, newQuantity),
      };
    }
  }

  if (membership.membership_type === 'family' && changeMembershipAction) {
    const familySize = membership.family_size?.toString() || '1';
    return {
      label: i18n.t('cart:changeFamilySize'),
      action: () => changeMembershipAction('family', familySize),
    };
  }
};

export const getDeviceCartUpsell = ({
  allProducts,
  engravingUpsellAction,
  accessories,
  currency,
  language,
  region,
}: DeviceCartUpsellOptions): CartUpsellContent | undefined => {
  const engravingGroup = getEngravingGroup(accessories);
  const hideEngravingUpsell =
    !engravingGroup ||
    !canAddEngraving(allProducts) ||
    hasAddedEngraving(allProducts) ||
    getCartMembershipType(allProducts) === 'family';
  if (hideEngravingUpsell) return;
  return {
    label: i18n.t('cart:addCustomEngraving', {
      price: formatDisplayPrice(engravingGroup.products[0].display_price, {
        currency,
        language,
        region,
      }),
    }),
    action: engravingUpsellAction,
  };
};

export const getEngravingUpsell = (
  engravingUpsellAction: () => void,
): CartUpsellContent | undefined => {
  return {
    label: i18n.t('cart:edit'),
    action: engravingUpsellAction,
  };
};

export const getCartItemUpsell = ({
  product,
  allProducts,
  currency,
  requiredProducts,
  addToCart,
  updateProductQuantity,
  accessories,
  engravingUpsellAction,
  changeMembershipAction,
  language,
  region,
  shouldSkipMemSelect,
}: CartItemUpsellOptions): CartUpsellContent | undefined => {
  const item = product.item;
  if (isMembership(item)) {
    return getMembershipCartUpsell({
      addToCart,
      currency,
      requiredProducts,
      updateProductQuantity,
      changeMembershipAction,
      product,
      shouldSkipMemSelect,
    });
  }
  if (isDevice(item) && engravingUpsellAction) {
    return getDeviceCartUpsell({
      allProducts,
      engravingUpsellAction,
      accessories,
      currency,
      language,
      region,
    });
  }
  if (isEngraving(item) && engravingUpsellAction) {
    return getEngravingUpsell(engravingUpsellAction);
  }
};

export function getCartDisplayItems({
  promoCode,
  cartProducts,
  engravingUpsellAction,
  learnMoreAction,
  changeMembershipAction,
  requiredProducts,
  currency,
  accessories,
  addToCart,
  updateProductQuantity,
  language,
  region,
  shouldSkipMemSelect,
}: CartDisplayItemsOptions): CartDisplayItem[] {
  return cartProducts.map((product) => {
    let membershipDiscount;
    const shouldCalculateDiscount =
      product.item.product_type === 'membership' &&
      product.item.membership_type !== 'family' &&
      promoCode;
    if (shouldCalculateDiscount) {
      const membership = product.item as MembershipProduct;

      const { displayPrice, strikedPrice } = getCardAmounts(
        membership,
        requiredProducts.join_memberships,
        currency,
        promoCode,
      );

      if (strikedPrice)
        membershipDiscount = getPercentSavings({
          baseRatePrice: strikedPrice,
          displayPrice,
        });
    }

    return {
      item: membershipDiscount
        ? {
            ...product,
            item: { ...product.item, percent_discount: membershipDiscount },
          }
        : product,
      alert: getCartItemAlert({
        product,
        learnMoreAction,
        requiredProducts,
        currency,
        language,
        region,
      }),
      upsell: getCartItemUpsell({
        accessories,
        addToCart,
        currency,
        requiredProducts,
        updateProductQuantity,
        product,
        allProducts: cartProducts,
        engravingUpsellAction,
        changeMembershipAction,
        language,
        region,
        shouldSkipMemSelect,
      }),
    };
  });
}

export function getCTAButtonsVariant({
  experimentName,
  experimentVariant,
}: CTAButtonsExperimentOptions): CartButtonVariants {
  if (experimentVariant === 'experiment') {
    return experimentName === DIRECT_TO_CHECKOUT_TRIALS
      ? 'preferCheckout'
      : 'preferAccessories';
  }

  return 'control';
}

export function getCTAButtonsExperimentName(
  membership: MembershipProduct,
): CTAButtonsExperimentName {
  return membership.membership_type === 'trial'
    ? DIRECT_TO_CHECKOUT_TRIALS
    : DIRECT_TO_CHECKOUT_PAID;
}

export function getBatteryCountInCart(
  cartProducts: CartProduct[],
  accessories: AccessoryGroup[],
): number {
  let batteryCount = 0;
  cartProducts.forEach((product) => {
    const { item, quantity } = product;
    if (isMembership(item)) {
      batteryCount += quantity;
    } else if (isAccessory(item)) {
      if (isPack(item)) {
        batteryCount +=
          countBatteriesInPack(item as PackProduct, accessories) * quantity;
      } else if (isBattery(item)) {
        batteryCount += quantity;
      }
    }
  });
  return batteryCount;
}

interface GetCartProductsFromFinalCartItemsParams {
  accessories: AccessoryGroup[];
  cartItem: FinalCartItem;
  requiredProducts: JoinProducts | null;
  wywConfigs: WhoopYourWayConfigurations;
}
export function getCartProductsFromFinalCartItems({
  accessories,
  cartItem,
  requiredProducts,
  wywConfigs,
}: GetCartProductsFromFinalCartItemsParams): CartProduct | undefined {
  // construct the cart item based on the SKU. Could be a Device, Membership, Accessory, or WYW
  // we want the default device product since it has the image and subtitle we want to display in the receipt
  let item: CartItemType | undefined = getDefaultDeviceProductFromSku(
    cartItem.sku,
    requiredProducts?.devices || [],
  );
  item ||= getMembershipFromSku(cartItem.sku, [
    ...(requiredProducts?.join_memberships || []),
    ...(requiredProducts?.gift_memberships || []),
    ...(requiredProducts?.family_memberships || []),
  ]);
  item ||= findAccessoryProductFromSku(accessories, cartItem.sku);
  item ||= buildWywProductFromSku(cartItem.sku, wywConfigs);

  if (!item) {
    return;
  }

  // construct the Engraving product, which is a type of Accessory
  if (item.accessory_type === 'engraving') {
    const engravingParams = cartItem.engraving_params[0];
    item = buildEngravingProductFromParams(
      engravingParams,
      item as AccessoryProduct,
    );
  }

  return {
    item,
    quantity: cartItem.quantity,
  };
}
