import type { HTMLAttributes } from 'react';
import clsx from 'clsx';
import styles from './layeredImage.module.scss';

interface LayeredImageProps extends HTMLAttributes<HTMLDivElement> {
  images: string[];
}

export default function LayeredImage({
  className,
  images,
  ...props
}: LayeredImageProps): JSX.Element {
  return (
    <div className={clsx(styles.layeredImage, className)} {...props}>
      {images.map((src) => (
        <img alt='' key={src} src={src} />
      ))}
    </div>
  );
}
