import React from 'react';
import type { CartUpsellContent } from '../../../types/Cart';

interface CartUpsellProps extends React.HTMLAttributes<HTMLButtonElement> {
  upsell: CartUpsellContent;
}

export function CartUpsell({
  upsell,
  ...props
}: CartUpsellProps): React.JSX.Element {
  return (
    <button
      className='w-fit pt-2 text-left text-xs text-black underline md:text-base'
      onClick={upsell.action}
      type='button'
      {...props}
    >
      {upsell.label}
    </button>
  );
}
